@import '~antd/dist/antd.css';

.body {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.header {
    margin-top:auto;
    margin-bottom:auto;
    padding:0 !important;
    background: white !important;
    line-height: 30px !important;
    display: flex;
    justify-content: space-between;
    color:#90a0b7;
    vertical-align: middle;
    border-bottom: 0.5px solid #EEEEEE;
    flex-shrink: 0;
}
.content {
    flex: 1 0 auto;
    overflow-y: auto;
}

.breadcrumb {
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-left: 36px !important;
    background-color: white;
    line-height: 30px !important;
    padding: 5px !important;
    justify-content: space-between;
    color: #90a0b7 !important;
    vertical-align: middle;
    align-self: flex-start;
}

.logo-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    letter-spacing: 0.05em;
    color:#F68A5B;
}

.login-form-button {
    width: 143px !important;
    height: 34px !important;
    border-radius: 10px !important;
}

.admin-panel {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.01em;
    color:#898989;
}
.trigger {
    padding-left:24.5px;
    padding-right:24.5px;
    margin-top: auto;
    margin-bottom: auto;
    /*margin:auto; */
}

.trigger-collapse {
    margin:auto;
}

.logo {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    color:#F68A5B;
    padding-left: 24px;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
}

#root {
    background-color: #EEEEEE;
}

.no-table-header-color thead>tr>th{
    background-color: #ffffff;
}

th  {
    text-align: left !important;
}

.table {
    padding: 0px  !important;

}

.ant-card-body {
    padding: 16px !important;
}

.ant-modal-body {
    padding: 16px !important;
}

.cover-img div {
    background-color: transparent;
}

/* .user-button :hover {
    background-color: #F6DAC6;
} */

/*----Upload-----*/
.avatar-uploader > div {
    width:370px !important;
    height:140px;
}

.ant-upload {
    width:370px;
    height:140px;
    align-items: center;
}

.ant-upload > div {
    width:370px;
    height:140px;
    justify-items: center;
}

.profile-col {
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    width:inherit;
}

.profile-col > p {
    margin-bottom: auto !important;
}

.desc-label {
    color:#37393A;
}

.desc-item {
    color:#898989;
}

.border-left {
    border-left: 1px solid #EEEEEE;
    padding-left:40px !important;
}

thead[class*="ant-table-thead"] th{
    color:#37393a !important;
    font-size:13px;
}

.paddingControl{
    padding-top: 5px;
}

.reward-form-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background-color: #F8F8F8;
    text-align: right;
}

.ant-form-vertical {
    padding-bottom: 0 !important;
}

.form-subheading {
    font-weight: bold;
    font-size: 15px;
    margin-top: 13px;
    margin-bottom: 16px;
}

.ant-form-vertical .ant-form-item-control {
    line-height: 0 !important;
}

.ant-form-checkbox {
    padding-top: 8px !important;
    display: inline-block !important;
}

.form-text {
    padding-left: 6px !important;
    padding-top: 6px !important;
}

/* AuthState */
#custom_sider_body {
    height: 100%;
    display: flex;
    flex-direction: column;
}
#custom_sider_header {
    flex-shrink: 0;
    display: flex; 
    background-color: white;
    justify-content: space-between; 
    height: 64px; 
    border-bottom: 1px solid #EEEEEE;
    vertical-align: middle;
}
#custom_sider_menu {
    height: 0%;
    flex: 1 0 auto;
    overflow-y: auto;
}
#custom_sider_footer {
    flex-shrink: 0;
}

/* content layout */

.ant-layout-content {
    height: 100%;
}

/*!*!* -------------Scrollbar ------------------ **!*/
/*!* width *!*/
/*::-webkit-scrollbar {*/
/*    width: 10px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*    background: #f1f1f1;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*    background: #888;*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*    background: #555;*/
/*}*/

